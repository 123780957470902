<template>
  <page-view bg-color="#f5f5f5">
    <template v-slot:nav>
      <nav-bar title="添加员工" />
    </template>
    <div class="content">
      <van-cell-group>
        <van-field label="员工姓名" clearable v-model="formData.userName" input-align="right" :placeholder="inputPlaceholder" />
        <van-field label="登录账号" clearable v-model="formData.userAccount" input-align="right" :placeholder="inputPlaceholder" />
        <van-field label="登录密码"
                   :right-icon="rightIconName"
                   v-model="formData.password"
                   input-align="right"
                   :type="passwordType"
                   @click-right-icon="changeInputType"
                   :placeholder="inputPlaceholder"
        />
      </van-cell-group>
      <van-cell-group class="other">
        <van-cell title="选择职位" @click="linkToSelect()" :value="positionObj.displayName" clickable is-link />
        <van-cell title="员工权限" clickable @click="linkToPermissionConfig()" is-link />
      </van-cell-group>
      <van-button block class="submit-btn" @click="addSubmit()" color="#E4AF79">保存</van-button>
    </div>
    <template v-slot:routerView>
      <router-view></router-view>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import FormModel from '@/model/permission/add-staff/form'
import { Cell, CellGroup, Icon, Button, Field, Toast } from 'vant'
import { mapGetters } from 'vuex'

export default {
  data () {
    const formModel = new FormModel()
    return {
      formModel,
      formData: formModel.formData,
      inputPlaceholder: '必填',
      passwordType: 'password'
    }
  },
  components: {
    PageView,
    NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Field.name]: Field
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    // 验证不能为空
    testNotNull (value) {
      return value && value.length !== 0
    },
    // 验证姓名
    testName (value) {
      return value && value.length !== 0 && value.length < 12
    },
    // 验证姓名
    testPassword (value) {
      return value && value.length >= 6 && value.length <= 16
    },
    validateVal (data) {
      if (!this.testNotNull(data.userName)) {
        Toast('请输入员工姓名')
        return false
      }
      if (!this.testNotNull(data.userName.trim())) {
        Toast('员工姓名不能为空')
        return false
      }
      if (!this.testNotNull(data.userAccount)) {
        Toast('请输入登录账号')
        return false
      }
      if (!this.testNotNull(data.password)) {
        Toast('请输入登录密码')
        return false
      }
      if (!this.testPassword(data.password)) {
        Toast('密码为6-16位字符')
        return false
      }
      if (!this.testNotNull(this.positionObj.displayName)) {
        Toast('请选择职位')
        return false
      }
      return true
    },
    // 保存
    async addSubmit () {
      if (!this.validateVal(this.formData)) return
      const positionId = this.positionObj.id
      if (!positionId) return
      this.formData.positionId = positionId
      this.formData.permissionIds = await this.$store.dispatch('permission/permissionIds')
      this.formModel.submit().then(res => {
        if (!res.userId) {
          Toast(res.message)
          return
        }
        if (!res.code) {
          Toast(res.message)
          return
        }
        Toast('操作成功')
        if (!window.$bridge) return
          $bridge.navigation.popViewController() // eslint-disable-line
      })
    },
    linkToSelect () {
      // 跳转到选择职位
      this.$router.push('/permission/add-staff/select-position')
    },
    linkToPermissionConfig () {
      const positionId = this.positionObj.id
      if (!positionId) return
      this.$router.push('/permission/add-staff/config')
    },
    changeInputType () {
      if (this.inputType) {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
    }
  },
  computed: {
    ...mapGetters({
      positionObj: 'permission/getPositionObj'
    }),
    inputType () {
      return this.passwordType === 'password'
    },
    rightIconName () {
      if (this.passwordType === 'text') {
        return 'eye-o'
      } else {
        return 'closed-eye'
      }
    }
  },
  watch: {
    positionObj (val) {
      const positionId = val.id
      if (!positionId) return
      this.$store.dispatch('permission/cleanPermissionConfig')
      //        this.$store.dispatch('permission/setPositionId', positionId)
      this.$store.dispatch('permission/loadPermissionConfig', { positionId })
    }
  }
}
</script>
